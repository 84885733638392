import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { Transition, animated } from 'react-spring/renderprops';

import styles from './AnimatedRoute.module.scss';

/**
 * This is the AnimatedRoute component.
 *
 * @author Chris Andersson <chris@wbisbi.com>
 */
class AnimatedRoute extends Component {
  /**
   * Declare expected prop types.
   *
   * @type {object}
   */
  static propTypes = {
    children: func.isRequired,
    location: object.isRequired,
  };

  /**
   * Render AnimatedRoute.
   *
   * @return {Object}
   */
  render() {
    const { location, children } = this.props;

    return (
      <Transition
        native
        items={location}
        keys={location => location.pathname}
        from={{
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
        }}
        enter={{
          position: 'relative',
          opacity: 1,
        }}
        leave={{
          position: 'absolute',
          opacity: 0,
          pointerEvents: 'none',
        }}
      >
        {location => style => (
          <animated.div className={styles.wrapper} style={style}>
            {children(location)}
          </animated.div>
        )}
      </Transition>
    );
  }
}

export default AnimatedRoute;
