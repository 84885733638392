import React, { Component } from 'react';
import { TimelineMax, CSSPlugin, AttrPlugin } from 'gsap/all';

// Without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin]; // eslint-disable-line

export default class Notes extends Component {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();

    this.tl = null;
  }

  componentDidMount() {
    const paths = this.wrapper.current.querySelectorAll('path');

    this.tl = new TimelineMax({
      delay: 2,
      repeat: -1,
      repeatDelay: 1,
    });

    this.tl.staggerFromTo(paths, 0.3, {
      opacity: 0,
      scale: 0,
      x: -30,
      transformOrigin: 'center',
    }, {
      opacity: 1,
      scale: 1,
      x: 0,
      transformOrigin: 'center',
    }, 0.2);

    this.tl.set({}, {}, 2);

    this.tl.staggerTo(paths, 0.2, {
      opacity: 0,
      x: 5,
    }, 0.1);
  }

  componentWillUnmount() {
    this.tl.kill();
  }

  render() {
    return (
      <svg
        ref={this.wrapper}
        viewBox="0 0 320 119"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g key="Page-1" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
          <path d="M11.8514851,112.422631 L11.8514851,82.9573946 C11.8514851,82.8254892 11.746254,82.71875 11.6104602,82.71875 L10.5460126,82.71875 C10.4145993,82.71875 10.3050882,82.8254892 10.3050882,82.9573946 L10.3050882,110.028808 C8.7325092,109.254298 6.33202647,109.394881 4.07171791,110.560335 C0.926559867,112.179647 -0.751149783,115.153593 0.326439183,117.202466 C1.39964771,119.251772 4.82077365,119.600627 7.96593169,117.981315 C10.589817,116.630152 11.8645259,114.329184 11.8514851,112.422631" />
          <path d="M48.6976968,72.9654392 C47.4610329,72.3629478 46.65731,71.2008455 46.2165448,69.9321919 C46.0339793,69.4054992 45.9079222,68.8601817 45.8275064,68.3092335 C45.791428,68.0627794 45.7757795,66.3943418 45.760131,66.1457221 C45.7470906,65.9417153 45.7649125,65.6991595 45.6940597,65.5051148 C45.5584396,65.1360834 44.7777548,64.9212483 44.4130585,65.0269333 C44.1305166,65.1087959 44.1287779,65.4717634 44.1452958,65.7078222 C44.1700725,66.0694903 44.1722459,67.8466449 44.1709419,68.2100455 C44.1687685,68.9416112 44.1661604,69.6731769 44.1635523,70.4047426 C44.1605095,71.3619719 44.1570321,72.318768 44.1539893,73.2759973 C44.1500772,74.37876 44.1461651,75.4815228 44.1426877,76.5842855 C44.1387756,77.7528849 44.1344288,78.9219174 44.1305166,80.0905167 C44.1266045,81.2456889 44.1226924,82.400861 44.1187803,83.5556 C44.1153028,84.6172149 44.1113907,85.6788298 44.1079133,86.7404447 C44.1048705,87.6288054 44.1018278,88.5171662 44.098785,89.4055269 C44.0966116,90.0413697 44.0944382,90.6767793 44.0922648,91.312189 C44.0913955,91.6149506 44.0900914,91.9177123 44.0892221,92.2204739 C44.0892221,92.223939 44.0861793,92.2624881 44.0892221,92.2637875 C42.5291564,91.4906408 40.1458073,91.6309767 37.8998171,92.7948115 C34.7805551,94.4108397 33.1170357,97.3795499 34.1846092,99.4252484 C35.2521827,101.470947 37.9502399,101.917943 41.0695018,100.301914 C43.6732335,98.9526977 45.3823943,96.6557806 45.3702233,94.7530167 L45.3702233,76.0974413 C49.1784526,75.9817942 52.7080522,80.5730296 52.7080522,80.5730296 C56.2615592,86.8088801 50.4981403,93.0217745 50.4981403,93.0217745 C50.1164915,94.1999028 50.8971764,93.9512831 50.8971764,93.9512831 C52.9353897,92.7272424 55.3030903,88.8649739 55.3030903,88.8649739 C60.7535398,79.7899207 48.6976968,72.9654392 48.6976968,72.9654392" />
          <path d="M78.5357035,77.6717161 L77.2205693,77.65625 C77.126584,77.6550602 77.0477683,77.705028 77.0352369,77.7731658 L76.1583708,82.5437223 C75.5753324,82.6796951 74.6311927,82.7893213 73.6976057,82.4316734 C74.3357162,82.1524597 74.7776118,81.5567837 74.7776118,80.8645033 C74.7776118,79.9054256 73.9307551,79.1277458 72.8860346,79.1277458 C72.0358802,79.1277458 71.3166455,79.6431706 71.0785495,80.3521069 C70.720746,81.3093677 71.2595948,82.0719057 71.2595948,82.0719057 C72.354111,83.4958349 73.8318232,83.9231347 75.1054061,83.9231347 C75.3880214,83.9231347 75.6594245,83.900725 75.915658,83.8649905 L75.2693032,87.3809024 C74.6832968,87.4935569 73.8390782,87.5471587 73.0040933,87.2273652 C73.6422038,86.9481515 74.0840995,86.3521726 74.0840995,85.6601951 C74.0840995,84.7008145 73.2372428,83.9231347 72.1928521,83.9231347 C71.3423678,83.9231347 70.6231332,84.4385596 70.3850372,85.1481015 C70.0272336,86.1047566 70.5660825,86.8672946 70.5660825,86.8672946 C71.6609284,88.2915266 73.1386407,88.7185237 74.4118937,88.7185237 C74.6245972,88.7185237 74.831035,88.7064103 75.0295583,88.6852119 L73.367503,97.7287673 C73.3520037,97.8126525 73.4420317,97.8892697 73.557782,97.8907839 L74.8729162,97.90625 C74.9669015,97.9074398 75.0457172,97.857472 75.0582485,97.7893342 L78.7259824,77.8337327 C78.7414817,77.7498475 78.6514538,77.6732303 78.5357035,77.6717161" />
          <path d="M114.000532,74.3871681 C112.590628,73.5325739 111.146505,72.4884458 110.020314,71.3097826 C110.38546,71.4887397 110.81038,71.4874705 111.658488,71.7595023 C115.95144,73.1353144 118.82236,74.8876558 119.472952,79.0882828 C119.472952,79.0882828 119.474684,79.127628 119.47815,79.1970109 C118.065647,77.2454052 116.006884,75.6034842 114.000532,74.3871681 Z M120.714921,81.9725783 C120.644212,81.750708 120.56279,81.5314377 120.47494,81.3147675 C121.240308,79.2759009 120.819912,77.6439409 120.819912,77.6439409 C119.798279,74.4203216 116.844797,71.7448779 114.003164,69.9612487 C111.666349,68.4948248 110.611289,68.1143519 109.920486,65.8822154 C109.899917,65.7188461 109.867348,65.5511433 109.813781,65.4029409 C109.80221,65.3713071 109.781212,65.34444 109.761928,65.3167062 C109.761071,65.3128061 109.759785,65.3093394 109.758928,65.3054393 C109.758928,65.307606 109.758071,65.3089061 109.758071,65.3106394 C109.545945,65.0194347 108.924994,64.9431667 108.598019,65.0393683 C108.319898,65.1212697 108.33404,65.4532084 108.33404,65.7205794 C108.33404,65.7782137 108.335754,65.838448 108.336183,65.8973823 L108.28133,92.2483795 C106.743309,91.4748668 104.393637,91.6885037 102.179811,92.852456 C99.104197,94.4696824 97.4641835,97.4393643 98.5166719,99.486031 C99.5691603,101.532698 102.915182,101.881103 105.990797,100.263877 C108.557308,98.914455 109.55623,96.685352 109.54423,94.7816875 L109.54423,74.8835625 C110.269745,75.3151695 110.340453,75.4014043 111.476078,75.9270462 C115.540329,77.8086102 120.28424,82.8414258 118.629228,87.3828333 C118.40896,87.9869099 117.730585,89.1252951 117.433179,89.6925377 C116.756947,90.9825921 118.502381,90.6346198 119.202183,89.5937361 C120.763774,87.2705982 121.551855,84.6150881 120.714921,81.9725783 L120.714921,81.9725783 Z" />
          <path d="M147.297 94.704V65.239c0-.132-.098-.239-.224-.239h-.988c-.122 0-.224.107-.224.239V92.31c-1.46-.774-3.69-.634-5.788.532-2.92 1.619-4.478 4.593-3.478 6.642.997 2.049 4.174 2.398 7.094.779 2.436-1.352 3.62-3.653 3.608-5.56M153.223 92a1.693 1.693 0 1 0-3.386 0c0 .935.76 1.688 1.693 1.688.937 0 1.693-.753 1.693-1.688"/>
          <path d="M170.786714,80.2202498 L169.337464,80.1875 C169.233842,80.1852939 169.147673,80.2362079 169.134584,80.3068796 L168.137634,85.7846963 C167.489726,85.9381983 166.518227,86.0301475 165.557636,85.6110567 C166.260809,85.2611178 166.748376,84.5133656 166.748376,83.6447876 C166.748376,82.4414689 165.81469,81.4657435 164.662853,81.4657435 C163.725168,81.4657435 162.932553,82.1124276 162.670044,83.0022831 C162.275191,84.202942 162.869652,85.1596697 162.869652,85.1596697 C164.076391,86.9462199 165.705615,87.4823369 167.109779,87.4823369 C167.361017,87.4823369 167.604255,87.4637191 167.837313,87.4337026 L165.354028,101.07714 C165.33803,101.16453 165.438016,101.245841 165.565634,101.2485 L167.014884,101.28125 C167.118505,101.283456 167.204675,101.232542 167.217401,101.16187 L170.99832,80.3916096 C171.014318,80.3042199 170.913968,80.2229095 170.786714,80.2202498" />
          <path d="M203.076574,52.6320972 L203.076574,24.8086806 C203.076574,24.684125 202.978223,24.5833333 202.851308,24.5833333 L201.85646,24.5833333 C201.733639,24.5833333 201.631288,24.684125 201.631288,24.8086806 L201.631288,50.3716597 C200.161533,49.6403056 197.918006,49.7730556 195.805488,50.8735694 C192.865977,52.4026528 191.297964,55.2108889 192.305095,57.1455972 C193.308132,59.0807153 196.505567,59.4101319 199.445078,57.8810486 C201.8974,56.6051736 203.088762,54.4324167 203.076574,52.6320972" />
          <path d="M235.041156,7.53486632 C233.876405,6.96494271 233.11942,5.86565798 232.704286,4.66558159 C232.532336,4.16735937 232.41361,3.65151909 232.33787,3.13035243 C232.30389,2.89722048 232.289151,1.31897048 232.274413,1.08378993 C232.262131,0.890810761 232.278916,0.661366317 232.212183,0.477810761 C232.08445,0.128727428 231.349163,-0.0744947944 231.005674,0.0254774278 C230.739562,0.102914928 230.737924,0.44626215 230.753482,0.669560761 C230.776818,1.01167882 230.778865,2.69276909 230.777636,3.03652604 C230.775589,3.72854687 230.773133,4.42056771 230.770677,5.11258854 C230.767811,6.01807465 230.764536,6.92315104 230.76167,7.82863715 C230.757985,8.87178993 230.7543,9.91494271 230.751025,10.9580955 C230.747341,12.063526 230.743247,13.1693663 230.739562,14.2747969 C230.735877,15.367526 230.732193,16.4602552 230.728508,17.5525747 C230.725233,18.5568038 230.721548,19.561033 230.718273,20.5652622 C230.715407,21.4056024 230.712541,22.2459427 230.709676,23.086283 C230.707629,23.6877552 230.705582,24.2888177 230.703535,24.8898802 C230.702716,25.176276 230.701488,25.4626719 230.700669,25.7490677 C230.700669,25.7523455 230.697803,25.7888108 230.700669,25.7900399 C229.231322,25.0586858 226.986567,25.1914358 224.871184,26.2923594 C221.93331,27.821033 220.366526,30.6292691 221.372019,32.5643872 C222.377512,34.4995052 224.918674,34.9223385 227.856548,33.3936649 C230.30887,32.1173802 231.918642,29.9446233 231.907178,28.1447135 L231.907178,10.4975677 C235.493955,10.3881719 238.818305,14.7312274 238.818305,14.7312274 C242.165172,20.6299983 236.736902,26.5070538 236.736902,26.5070538 C236.377447,27.6214983 237.112734,27.3863177 237.112734,27.3863177 C239.032423,26.2284427 241.26244,22.5749497 241.26244,22.5749497 C246.39594,13.9904497 235.041156,7.53486632 235.041156,7.53486632" />
          <path d="M262.650224,14.3556113 L261.441771,14.3402778 C261.35541,14.3390982 261.282987,14.3886375 261.271473,14.4561912 L260.465736,19.1858478 C259.929993,19.3206549 259.06244,19.4293413 258.204585,19.0747596 C258.790933,18.7979397 259.196982,18.2073707 259.196982,17.5210255 C259.196982,16.5701703 258.418821,15.7991579 257.458847,15.7991579 C256.677656,15.7991579 256.016764,16.3101638 255.797982,17.013022 C255.469203,17.9620758 255.964341,18.7180763 255.964341,18.7180763 C256.970072,20.1297976 258.327915,20.5534341 259.498186,20.5534341 C259.757877,20.5534341 260.007264,20.5312164 260.242712,20.4957883 L259.648789,23.981557 C259.110318,24.0932457 258.334581,24.1463879 257.567329,23.8293361 C258.153677,23.5525162 258.559727,22.9616469 258.559727,22.275602 C258.559727,21.3244465 257.781566,20.5534341 256.821894,20.5534341 C256.0404,20.5534341 255.379508,21.06444 255.160726,21.7678987 C254.831947,22.716352 255.327086,23.4723525 255.327086,23.4723525 C256.333119,24.884374 257.690962,25.3077103 258.860931,25.3077103 C259.05638,25.3077103 259.246072,25.2957008 259.428491,25.2746841 L257.901259,34.2407055 C257.887017,34.3238716 257.969742,34.3998319 258.076103,34.4013331 L259.284556,34.4166667 C259.370917,34.4178462 259.443339,34.3683069 259.454854,34.3007532 L262.825067,14.5162389 C262.83931,14.4330728 262.756584,14.3571125 262.650224,14.3556113" />
          <path d="M284.89342,25.8591694 C283.539405,25.0339655 282.152527,24.0257462 281.070979,22.8876186 C281.42165,23.0604211 281.829727,23.0591956 282.644216,23.3218719 C286.766994,24.6503683 289.52411,26.3424447 290.148912,30.3986074 C290.148912,30.3986074 290.150576,30.4365995 290.153904,30.5035962 C288.797393,28.6191085 286.82024,27.033655 284.89342,25.8591694 Z M291.510415,33.1017627 C291.441778,32.8926022 291.362742,32.6858927 291.277466,32.4816343 C292.020406,30.559563 291.61233,29.021089 291.61233,29.021089 C290.620633,25.9821329 287.753699,23.4599505 284.995335,21.7784956 C282.726996,20.3960749 281.702853,20.0373972 281.032293,17.9331275 C281.012326,17.7791167 280.980712,17.6210207 280.928714,17.4813079 C280.917483,17.4514862 280.8971,17.4261582 280.87838,17.4000131 C280.877548,17.3963365 280.876301,17.3930683 280.875469,17.3893917 C280.875469,17.3914343 280.874637,17.3926598 280.874637,17.3942939 C280.668727,17.1197706 280.065971,17.0478717 279.748579,17.1385624 C279.478608,17.2157721 279.492335,17.5286959 279.492335,17.7807507 C279.492335,17.8350834 279.493999,17.8918673 279.494415,17.9474256 L279.441169,42.7889203 C277.948217,42.0597179 275.667398,42.2611167 273.518445,43.3583927 C270.532957,44.8829772 268.941001,47.6825424 269.962648,49.611967 C270.984295,51.5413916 274.232267,51.869839 277.217756,50.3452546 C279.709061,49.0731334 280.67871,46.9717233 280.667063,45.1771092 L280.667063,26.4188373 C281.371317,26.82572 281.439954,26.9070148 282.542301,27.4025456 C286.487455,29.1763253 291.092355,33.9208388 289.48584,38.2020943 C289.272026,38.7715666 288.61353,39.8447401 288.324839,40.3794886 C287.668423,41.5956429 289.36271,41.267604 290.042005,40.2863467 C291.557836,38.0962885 292.322824,35.5928978 291.510415,33.1017627 L291.510415,33.1017627 Z" />
          <path d="M314.88 35.698V7.921a.224.224 0 0 0-.226-.225h-.996c-.123 0-.225.1-.225.225v25.52c-1.472-.73-3.72-.597-5.835.501-2.944 1.527-4.514 4.33-3.506 6.262 1.005 1.932 4.207 2.26 7.151.734 2.456-1.274 3.65-3.443 3.637-5.24M320 34.203c0-.943-.762-1.71-1.707-1.71a1.71 1.71 0 0 0 0 3.42c.945 0 1.707-.763 1.707-1.71"/>
        </g>
      </svg>
    );
  }
}
