import React from 'react';
import { shape, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'clsx';

import Symbol from 'scripts/components/Symbol/Symbol';
import Notes from 'scripts/components/Notes/Notes';

import introBgImage from './images/intro-bg.jpg';

import styles from './Intro.module.scss';

const Intro = ({ match: { params: { language } } }) => {

  const date = language === 'en' ? '12 October 2019' : '12 Oktober 2019';

  return (
    <div className={styles.wrapper}>
      <Symbol />

      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img src={introBgImage} alt="" className={styles.image} />

          <div
            className={classNames(styles.notes, {
              [styles.large]: language === 'en',
            })}
          >
            <Notes />
          </div>
        </div>
      </div>

      <footer className={styles.footer}>
        <h3>{date}</h3>
      </footer>
    </div>
  );
};

Intro.propTypes = {
  match: shape({
    params: object.isRequired,
  }).isRequired,
};

export default withRouter(Intro);
