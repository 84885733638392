import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './styles/main.scss';

import App from './scripts/components/App/App';

if (
  'ontouchstart' in window ||
  (window.DocumentTouch && document instanceof window.DocumentTouch)
) {
  document.body.classList.remove('no-touch');
  document.body.classList.add('touch');
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
