import React from 'react';
import { object } from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';

import AnimatedRoute from 'scripts/components/AnimatedRoute/AnimatedRoute';
import StartPage from 'scripts/components/StartPage/StartPage';
import GiftsPage from 'scripts/components/GiftsPage/GiftsPage';

import styles from './App.module.scss';

const App = ({ location }) => {
  return (
    <div className={styles.wrapper}>
      <AnimatedRoute location={location}>
        {location => (
          <Switch location={location}>
            <Route exact path="/:language(sv|en)?/:name(kobayashi|ito|schley|louisevictor)?" render={() => <StartPage />} />
            <Route exact path="/presenter" render={() => <GiftsPage />} />
          </Switch>
        )}
      </AnimatedRoute>
    </div>
  );
};

App.propTypes = {
  location: object.isRequired,
};

export default withRouter(App);
