import React from 'react';
import Symbol from 'scripts/components/Symbol/Symbol';

import styles from './GiftsPage.module.scss';

const GiftsPage = () => {
  return (
    <div className={styles.wrapper}>
      <Symbol />

      <div className={styles.content}>
        <p>
          Att ni vill vara med och fira med oss på vår<br />
          bröllopsdag är en gåva i sig, men om ni ändå vill <br />
          ge något så har vi sammanställt en liten önskelista här<br />
          som vi hoppas kan vara till nytta.
        </p>

        <h3>Gustavsberg-servisen Natur i färgen Snövit</h3>
        <p>
          <a
            href="https://royaldesign.se/natur-tallrik-snovit#/116215"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mattallrik Ø28 cm
          </a>
          <br />
          <a
            href="https://royaldesign.se/natur-tallrik-snovit#/116217"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tallrik Ø22 cm
          </a>
          <br />
          <a
            href="https://royaldesign.se/natur-djup-tallrik-snovit#/116219"
            target="_blank"
            rel="noopener noreferrer"
          >
            Djup tallrik Ø23 cm
          </a>
          <br />
          <a
            href="https://royaldesign.se/natur-graddkanna-025l-snovit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gräddkanna 25 cl
          </a>
        </p>

        <h3>Svenskt Tenn</h3>
        <p>
          <a
            href="https://www.svenskttenn.se/sv/sortiment/dukning/glas-koppar/dricksglas/dricksglas-bris/107699/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dricksglas Bris, valfria färger
          </a>
          <br />
          <a
            href="https://www.svenskttenn.se/sv/sortiment/dekorationer/krukor-krukfat/kruka-hortus-1942/107118/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kruka Hortus 1942, grön
          </a>
        </p>

        <h3>Gense Nobel Fiskbestick</h3>
        <p>
          <a
            href="https://bagarenochkocken.se/dukade-bordet/bestick/bestickset/gense-nobel-fiskbestick-guld-22/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gense Nobel Fiskbestick Guld
          </a>
        </p>

        <h3>Alessi fruktskål</h3>
        <p>
          <a
            href="https://royaldesign.se/rund-fruktskal-245-mm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Rund fruktskål Ø245 mm
          </a>
        </p>

        <h3>Bröllopsresa</h3>
        <p>Bidrag till vår bröllopsresa på konto 8480-6,914 721 011-7, Swedbank.</p>

        <p>
          Kontakta gärna toastmaster Hedda Wittbom (0708-12 28 86)<br />
          så kan hon bocka av från listan.
        </p>
      </div>
    </div>
  );
};

export default GiftsPage;
