import React from 'react';
import { Link } from 'react-router-dom';

import scIcon from 'media/gfx/sc-symbol.svg';

import styles from './Symbol.module.scss';

const Symbol = () => (
  <Link to="/">
    <img className={styles.icon} src={scIcon} alt="" />
  </Link>
);

export default Symbol;
