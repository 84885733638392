import React from 'react';

const InfoEnglish = () => {
  return (
    <React.Fragment>
      <p>
        You are invited to attend the wedding of
      </p>

      <header>
        <h2>
          SIGRID WITTBOM
          <span>AND</span>
          CHRISTOFFER ANDERSSON
        </h2>
      </header>

      <p>
        at{' '}
        <a
          href="https://goo.gl/maps/2ZFmKkgyYPCfPAMX7"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sankt Johannes Church
        </a>{' '}
        in Stockholm <br />
        on Saturday <b>12 October 2019</b> at <b>3.30 P.M</b>.
      </p>

      <p>
        Dinner and celebrations will be held at the <br />
        <a
          href="https://goo.gl/maps/cuY4X4SQ5bSZPzHLA"
          target="_blank"
          rel="noopener noreferrer"
        >
          Eric Ericson hall
        </a>{' '}
        at Skeppsholmen in Stockholm.
      </p>

      <h3>DRESS CODE</h3>
      <p>Dark lounge suit</p>

      <h3>RSVP</h3>
      <p>
        RSVP to{' '}
        <a href="mailto:osa@sigridochchris.se">osa@sigridochchris.se</a>{' '}
        by 1 August 2019.
      </p>
    </React.Fragment>
  );
};

export default InfoEnglish;
