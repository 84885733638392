import React from 'react';
import { shape, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTrail, config, animated } from 'react-spring';

import Intro from 'scripts/components/Intro/Intro';
import Info from 'scripts/components/Info/Info';
import Toastmasters from 'scripts/components/Toastmasters/Toastmasters';

import styles from './StartPage.module.scss';

const StartPage = ({ match: { params: { language } } }) => {
  const items = [
    <Intro key="intro" />,
    <Info key="info" />,
  ];

  if (language !== 'en') {
    items.push(<Toastmasters key="toastmasters" />);
  }

  const trail = useTrail(items.length, {
    opacity: 1,
    y: 0,
    from: {
      opacity: 0,
      y: 250,
    },
    config: config.gentle,
  });

  return (
    <div className={styles.wrapper}>
      {trail.map(({ y, ...rest }, index) => (
        <animated.div
          key={`section-${index}`}
          style={{
            ...rest,
            transform: y.interpolate(y => `translate3d(0,${y}px,0)`),
          }}
        >
          {items[index]}
        </animated.div>
      ))}
    </div>
  );
};

StartPage.propTypes = {
  match: shape({
    params: object.isRequired,
  }).isRequired,
};

export default withRouter(StartPage);
