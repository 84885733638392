import React from 'react';
import { Link } from 'react-router-dom';

import childIcon from 'media/gfx/child-symbol.svg';

import styles from './Toastmasters.module.scss';

const Toastmasters = () => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <h3>TOASTMASTERS</h3>
      <p>
        Hedda Wittbom
        <br />
        0708-12 28 86
      </p>
      <p>
        Vidar J Lindgren
        <br />
        0735-87 99 92
      </p>

      <p>
        Tal anmäles till{' '}
        <a href="mailto:tal@sigridochchris.se">tal@sigridochchris.se</a>
      </p>

      <img src={childIcon} alt="" className={styles.childIcon} />

      <p>
        Under bröllopsmiddagen och efterföljande fest <br />
        önskar vi fira utan barn, men barn är såklart hjärtligt <br />
        välkomna till vigseln.
      </p>

      <h3>Presenter</h3>
      <p>
        Om du har funderingar kring presenter, klicka{' '}
        <Link to="/presenter">här</Link>.
      </p>
    </div>
  </div>
);

export default Toastmasters;
