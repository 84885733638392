import React from 'react';
import { bool } from 'prop-types';

const InfoSwedish = ({ lateRsvp }) => (
  <React.Fragment>
    <p>
      Hjärtligt välkomna att övervara vigseln mellan
    </p>

    <header>
      <h2>
        SIGRID WITTBOM
        <span>OCH</span>
        CHRISTOFFER ANDERSSON
      </h2>
    </header>

    <p>
      i{' '}
      <a
        href="https://goo.gl/maps/2ZFmKkgyYPCfPAMX7"
        target="_blank"
        rel="noopener noreferrer"
      >
        S:t Johannes kyrka
      </a>{' '}
      i Stockholm <br />
      lördagen den <b>12 OKTOBER 2019 KL.15.30</b>.
    </p>

    <p>
      Efter vigseln bjuder vi till bröllopsmiddag i <br />
      <a
        href="https://goo.gl/maps/cuY4X4SQ5bSZPzHLA"
        target="_blank"
        rel="noopener noreferrer"
      >
        Eric Ericsonhallen
      </a>{' '}
      på Skeppsholmen. Från kyrkan <br />
      åker vi gemensamt med buss till middagen.
    </p>

    <h3>Klädsel</h3>
    <p>Mörk kostym</p>

    <h3>O.S.A.</h3>

    {lateRsvp && (
      <p>
        Senast den 31 augusti 2019 till{' '}
        <a href="mailto:osa@sigridochchris.se">osa@sigridochchris.se</a> <br />
        och meddela även eventuell specialkost.
      </p>
    )}

    {!lateRsvp && (
      <p>
        Senast den 1 augusti 2019 till{' '}
        <a href="mailto:osa@sigridochchris.se">osa@sigridochchris.se</a> <br />
        och meddela även eventuell specialkost.
      </p>
    )}
  </React.Fragment>
);

InfoSwedish.propTypes = {
  lateRsvp: bool.isRequired,
};

export default InfoSwedish;
