import React from 'react';
import { shape, object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import InfoSwedish from './InfoSwedish/InfoSwedish';
import InfoEnglish from './InfoEnglish/InfoEnglish';

import styles from './Info.module.scss';

const Info = ({ match: { params: { name, language } } }) => {
  const lateRsvp = name === 'louisevictor';

  const translations = {
    sv: <InfoSwedish lateRsvp={lateRsvp} />,
    en: <InfoEnglish lateRsvp={lateRsvp} />,
  };

  const names = {
    kobayashi: 'Keita Kobayashi',
    ito: 'Sakiko Ito',
    schley: 'Kate & Jonathan Schley',
    louisevictor: 'Louise & Victor',
  };

  const languageCode = language || 'sv';

  return (
    <section className={styles.wrapper}>
      {name && (
        <header className={styles.header}>
          <p>{names[name]}</p>
        </header>
      )}

      <article className={styles.content}>
        {translations[languageCode]}
      </article>
    </section>
  );
};

Info.propTypes = {
  match: shape({
    params: object.isRequired,
  }).isRequired,
};

export default withRouter(Info);
